import {routes_permissions} from "@/utils/constant";
import i18n from "@/plugins/i18n";

const default_menu = [
    {
        id: "documents-kyc-menu",
        name: "Documentos KYC",
        link_to: "kyc",
        permissions: routes_permissions['kyc'],
        icon: require('@/assets/img/home/clientes.svg'),
        iconOff: require('@/assets/img/home/clientes2.svg')
    },
    {
        id: "reports-menu",
        name: "Reportes",
        link_to: "reports",
        permissions: routes_permissions['reports'],
        icon: require('@/assets/img/home/profile-card.svg'),
        iconOff: require('@/assets/img/home/profile-card2.svg')
    },
    {
        id: "line-of-credit-menu",
        name: "Línea de crédito",
        link_to: "credit-line",
        permissions: routes_permissions['credit-line'],
        icon: require('@/assets/img/home/clientes.svg'),
        iconOff: require('@/assets/img/home/clientes2.svg')
    },
    {
        id: "invoices-menu",
        name: "Facturas",
        link_to: "invoice-analysis",
        permissions: routes_permissions['invoice-analysis'],
        icon: require('@/assets/img/home/facturas.svg'),
        iconOff: require('@/assets/img/home/facturas2.svg')
    },
    {
        id: "invoices-analysis-menu",
        name: "Cuentas por pagar",
        link_to: "client_invoices",
        permissions: routes_permissions['client_invoices'],
        icon: require('@/assets/img/home/cuentasXcobrar.svg'),
        iconOff: require('@/assets/img/home/cuentasXcobrar2.svg')
    },
    {
        id: "supplier-menu",
        name: "Clientes y proveedores",
        link_to: "clients-and-suppliers",
        permissions: routes_permissions['clients-and-suppliers'],
        icon: require('@/assets/img/home/cuentasXcobrar.svg'),
        iconOff: require('@/assets/img/home/cuentasXcobrar2.svg')
    },
    {
        id: "brokers-menu",
        name: "Solvento brokers",
        link_to: "brokers",
        permissions: routes_permissions['brokers'],
        icon: require('@/assets/img/home/user-group.svg'),
        iconOff: require('@/assets/img/home/user-group2.svg')
    },
    {
        id: "settings-menu",
        name: i18n.t("Settings"),
        link_to: "carrier-settings-list",
        permissions: routes_permissions['settings'],
        icon: 'icon-settings',
        iconOff: 'icon-settings'
    },
]
export default {
    namespaced: true,
    state: {
        showMenuStore: false,
        messages: [],
        breadcrumbs: [],
        overlay: false,
        layout: 'app-layout',
        menu: [
            ...default_menu
        ],
    },
    getters: {
        getShowMenuStore(state) {
            return state.showMenuStore;
        },
        getMessages(state) {
            return state.messages;
        },
        getBreadcrumbs(state) {
            return state.breadcrumbs;
        },
        getOverlay(state) {
            return state.overlay;
        },
        getLayout(state) {
            return state.layout;
        },
        getMenu(state) {
            return state.menu;
        }
    },
    mutations: {
        setShowMenuStore(state, showMenuStore) {
            state.showMenuStore = showMenuStore;
        },
        reportMessage(state, message) {
            state.messages.push(message);
        },
        confirmMessage(state, message) {
            var indice = state.messages.indexOf(message);
            state.messages.splice(indice, 1);
        },
        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
        clearMessages: function (state) {
            state.messages = [];
        },
        setOverlay: function (state, overlay) {
            state.overlay = overlay;
        },
        setLayout: function (state, layout) {
            state.layout = layout;
        },
        addMenu: function (state, menu) {
            const position = state.menu.length - 1;
            state.menu.splice(position, 0, menu);
        },
        resetMenu: function (state) {
            state.menu = [...default_menu];
        }
    },
    actions: {
        borrarBreadcrumb: function ({commit}) {
            commit("setBreadcrumbs", []);
        }
    }
}