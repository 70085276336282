import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import {authInterceptor, errorInterceptor} from './config/Interceptors'
import {IHttpRequest} from './interfaces/HttpRequest'
import store from '@/plugins/store'

type IConfig = AxiosRequestConfig

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    fullResponse?: boolean;
}

class HttpRequest implements IHttpRequest {
    api: ReturnType<typeof axios.create>

    constructor(baseURL: string) {
        this.api = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
            },
        })
        this.api.interceptors.request.use((config: AxiosRequestConfig) => {
            const token = store.state.security.jwt || '';
            return authInterceptor(config, `Bearer ${token}`)
        })

        this.api.interceptors.response.use(
            (response: AxiosResponse) => {
                const config = response.config as ExtendedAxiosRequestConfig;
                return config.fullResponse ? response : response.data;
            },
            errorInterceptor
        );
    }

    async get<T>(url: string, conf?: IConfig): Promise<AxiosResponse<T>> {
        try {
            return await this.api.get<T>(url, conf)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async post<T, Y>(url: string, data: Y, conf?: IConfig): Promise<AxiosResponse<T>> {
        try {
            return await this.api.post<Y, AxiosResponse<T>>(
                url,
                data,
                conf,
            )
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async put<T, Y>(url: string, data: Y, conf?: IConfig): Promise<AxiosResponse<T>> {
        try {
            return await this.api.put<Y, AxiosResponse<T>>(
                url,
                data,
                conf,
            )
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async patch<T, Y>(url: string, data: Y, conf?: IConfig): Promise<AxiosResponse<T>> {
        try {
            return await this.api.patch<Y, AxiosResponse<T>>(
                url,
                data,
                conf,
            )
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async delete<T>(url: string, conf?: IConfig): Promise<AxiosResponse<T>> {
        try {
            return await this.api.delete<T>(
                url,
                conf,
            )
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default HttpRequest
