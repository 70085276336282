export const roles = {
    ADMIN: 1,
    OPERATIONS_OFFICER: 2,
    RISK_ANALYST: 3,
    KYC_ANALYST: 4
}

export const permissions = {
    ADMIN: 1,
    ANALYZE_RISK: 3,
    OPERATIONS: 2,
    ANALYZE_KYC: 4
}

export const routes_permissions = {
    'kyc': [permissions.ANALYZE_KYC, permissions.ADMIN],
    'reports': [permissions.ANALYZE_KYC, permissions.ADMIN, permissions.ANALYZE_RISK, permissions.OPERATIONS],
    'credit-line': [permissions.ANALYZE_RISK, permissions.ADMIN],
    'invoice-analysis': [permissions.OPERATIONS, permissions.ADMIN],
    'client_invoices': [permissions.OPERATIONS, permissions.ADMIN],
    'download_files': [permissions.OPERATIONS, permissions.ADMIN],
    'providers_export': [permissions.OPERATIONS, permissions.ADMIN],
    'clients-and-suppliers': [permissions.OPERATIONS, permissions.ADMIN],
    'brokers': [permissions.OPERATIONS, permissions.ADMIN],
    'settings': [permissions.ADMIN],
};

export const has_access_to = (actual = [], some = []) => {
    for (const i in actual) {
        let _permission = permissions[actual[i]];
        if (some.includes(_permission))
            return true;
    }
    return false;
}