export default [
  {
    path: 'settings',
    name: 'settings',
    component: () => import('@/modules/settings/views/Settings.vue'),
    children: [
      {
        path: 'carrier-list',
        name: 'carrier-settings-list',
        component: () => import('@/modules/settings/views/carrier-list/CarrierList.vue'),
      },
      {
        path: ':carrierId/detail',
        name: 'carrier-settings-detail',
        component: () => import('@/modules/settings/views/carrier-detail/CarrierDetail.vue'),
      },
    ]
  },
]
