import { getRequestParams } from '@/shared/general-request/config/RequestParams'
import HttpRequest from '@/shared/general-request/HttpRequest'

const { apiV1, apiV12, apiV2, workflow } = getRequestParams()

const httpClientV1: HttpRequest = new HttpRequest(apiV1.url)
const httpClientV2: HttpRequest = new HttpRequest(apiV2.url)

const httpClientV12: HttpRequest = new HttpRequest(apiV12.url)
const httpClientWorkflow: HttpRequest = new HttpRequest(workflow.url)

export { httpClientV1, httpClientV12, httpClientV2, httpClientWorkflow }