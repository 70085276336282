import Vue from 'vue'
import Router from 'vue-router'
import store from '@/plugins/store/index'
import {has_access_to, routes_permissions} from '@/utils/constant'
import podsRoutes from '@/modules/proof-of-deliveries/routes'
import settingsRoutes from '@/modules/settings/routes'

Vue.use(Router);

let router = new Router({
    mode: 'history', //UNCOMMET THIS LINE IF YOU WANT TO DISABLE # IN PATH
    routes: [
        {path: '/login', name: 'Login', component: () => import('@/components/app/Login.vue'), meta: {guest: true}},
        {
            path: '/profile/:key',
            name: 'ConfirmProfile',
            component: () => import('@/components/app/VerifyUser/ConfirmProfile.vue'),
            meta: {guest: true}
        },
        {
            path: '/signup-notification',
            component: () => import('@/components/app/SignupNotification/SignupNotification.vue'),
            meta: {guest: true},
            name: 'signup-notification'
        },
        {
            path: '/validate/:key',
            name: 'verify-signup',
            component: () => import('@/components/app/VerifyUser/index.vue'),
            meta: {guest: true}
        },
        {
            //TODO: ADD THE ROUTES HERE
            path: '/', component: () => import('@/layouts/Dashboard.vue'),
            children: [
                {
                    path: '',
                    name: 'kyc',
                    component: () => import('@/components/kyc'),
                    meta: {authorize: routes_permissions['kyc'], redirect: 'credit-line'}
                },
                {
                    path: 'reports',
                    name: 'reports',
                    component: () => import('../../components/Reports'),
                    meta: {authorize: routes_permissions['reports'], redirect: ''}
                },
                {
                    path: 'credit-line',
                    name: 'credit-line',
                    component: () => import('@/components/risk/credit-line-analysis'),
                    meta: {authorize: routes_permissions['credit-line'], redirect: 'invoice-analysis'}
                },
                {
                    path: 'invoices',
                    name: 'invoice-analysis',
                    component: () => import('@/components/risk/invoices-analysis'),
                    meta: {authorize: routes_permissions['invoice-analysis'], redirect: 'client_invoices'}
                },
                {
                    path: 'client_invoices',
                    name: 'client_invoices',
                    component: () => import('@/components/risk/client_invoices'),
                    meta: {authorize: routes_permissions['client_invoices'], redirect: 'Login'}
                },
                {
                    path: 'clients-and-suppliers',
                    name: 'clients-and-suppliers',
                    component: () => import('@/modules/clients-and-suppliers'),
                    meta: {authorize: routes_permissions['client_invoices'], redirect: 'Login'}
                },
                {
                    path: 'download_files',
                    name: 'download_files',
                    component: () => import('@/components/DownloadFiles'),
                    meta: {authorize: routes_permissions['download_files'], redirect: 'Login'}
                },
                {
                    path: 'providers_export',
                    name: 'providers_export',
                    component: () => import('@/components/ProvidersExport'),
                    meta: {authorize: routes_permissions['providers_export'], redirect: 'Login'}
                },
                {
                    path: 'download_bulk_files',
                    name: 'download_bulk_files',
                    component: () => import('@/components/DownloadBulkFiles'),
                    meta: {authorize: routes_permissions['providers_export'], redirect: 'Login'}
                },
                {
                    path: 'brokers',
                    name: 'brokers',
                    component: () => import('@/components/Brokers'),
                    meta: {authorize: routes_permissions['brokers'], redirect: 'Login'}
                },
                ...podsRoutes,
                ...settingsRoutes,
            ]
        },
        {
            path: '*',
            redirect: {path: '/'}
        }
    ]
});

router.beforeEach(function (to, from, next) {
    if (to.meta.guest) {
        next();
    } else {
        const {authorize, redirect} = to.meta;
        const {email, permissions} = store.getters['security/session'];
        if (authorize) {
            if (!email || !Object.keys(permissions).length) {
                return router.push({name: 'Login'});
            }
            let has_access = has_access_to(permissions, authorize);
            if (authorize.length && !has_access) {
                if (redirect)
                    return router.push({name: redirect});
                return router.push({name: 'Login'});
            }
        }
        next()
    }
});

export default router;